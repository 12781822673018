<template>
    <div
        v-if="isOpen"
        class="overlay"
        :class="{
            'overlay_full': position == 'full',
            'overlay_bottom': position == 'bottom'
        }"
    >
        <div class="overlay__header">
            <div
                @click="close"
                class="overlay__close"
            >
                <slot name="close-icon">
                    <svg
                        class="overlay__icon-close icon icon-close"
                        :class="{
                            'overlay__icon-close_left': closeIconPosition == 'left',
                            'overlay__icon-close_right': closeIconPosition == 'right'
                        }"
                    >
                        <use xlink:href="/static/autogen/img/sprite.svg#close"></use>
                    </svg>
                </slot>
                <!-- <svg class="icon icon-arrow2-left">
                    <use xlink:href="/static/autogen/img/sprite.svg#arrow2-left"></use>
                </svg> -->
            </div>
            <div class="overlay__title">
                <slot name="title">
                </slot>
            </div>
        </div>
        <div class="overlay__body">
            <slot name="body">
            </slot>
        </div>
    </div>
</template>
<script>

export default {
    props: {
        position: {
            type: String,
            default: 'full',
        },
        closeIconPosition: {
            type: String,
            default: 'right',
        }
    },
    computed: {
    },
    data () {
        return {
            isOpen: false,
        }
    },
    methods: {
        open() {
            document.querySelector('body').classList.add('overflow-prevent');
            // hack for srp, ToDo check for better solution
            // required for header overlays
            let $results = document.querySelector('.search-page__results');
            $results && $results.classList.add('overflow-hidden');
            this.isOpen = true;
        },
        close(event) {
            document.querySelector('body').classList.remove('overflow-prevent');
            let $results = document.querySelector('.search-page__results');
            $results && $results.classList.remove('overflow-hidden');
            this.isOpen = false;
            this.$emit('close');
        },
    },
}
  
</script>

<style lang="scss" scoped>
@use "activeSite" as siteVars;

.overlay {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100vw;
    background-color: #E8E9F3;
    z-index: 2147483648; // to cover freshworks widget
    overflow: hidden;

    &__icon-close {
        position: absolute;
        top: 0;
        bottom: 0;
        margin-top: auto;
        margin-bottom: auto;
        width: 18px;
        height: 18px;

        &_left {
            left: 10px;
        }
        &_right {
            right: 10px;
        }
    }

    &__header {
        padding: 12px 10px;
        color: white;
        font-family: Manrope;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 26px;
        text-align: center;
        position: relative;
    }

  // &__back {
  //   position: absolute;
  //   left: 10px;
  //   top: 10px;

  //   & .icon {
  //     width: 30px;
  //     height: 30px;
  //   }
  // }

  // &__close {
  //   text-align: right;

  //   & .icon {
  //     width: 18px;
  //     height: 18px;
  //     fill: #3E3C49;
  //   }
  // }

  // &__header {
  //   color: #1E1C2A;
  //   text-align: center;
  //   font-family: Manrope;
  //   font-size: 18px;
  //   font-style: normal;
  //   font-weight: 800;
  //   line-height: 27px;
  // }

    &__body {
        padding: 10px;
        height: calc(100% - 50px);
        overflow: auto;
    }

    &_full {
        height: 100%; // fallback
        height: 100dvh;
        top: 0;
    }
    &_full &__header {
        background: #0064D3;
    }
    &_full &__icon-close {
        fill: white;
    }

    &_bottom {
        background: #FFF;
        border-radius: 20px 20px 0 0;
        padding: 16px;
        padding-top: 4px;
    }
}

</style>
