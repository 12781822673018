var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { width: "100%" } },
    [
      _c(
        "date-range-picker",
        _vm._b(
          {
            ref: "picker",
            attrs: { "date-format": _vm.disablePriorDates },
            on: {
              "start-selection": _vm.setFirstDate,
              "finish-selection": _vm.setEndDate,
              update: _vm.onCalendarUpdate,
              toggle: _vm.onCalendarToggle,
            },
            scopedSlots: _vm._u([
              {
                key: "input",
                fn: function (picker) {
                  return [
                    _c("div", [
                      _vm.displayLegend
                        ? _c(
                            "div",
                            { staticClass: "form-element__title  GFont_8" },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(_vm.$t("common.checkIn")) +
                                  " / " +
                                  _vm._s(_vm.$t("common.checkOut")) +
                                  "\n        "
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c("div", { staticClass: "form-element__content" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm._f("date")(picker.startDate)) +
                            " - " +
                            _vm._s(_vm._f("date")(picker.endDate)) +
                            "\n        "
                        ),
                      ]),
                    ]),
                  ]
                },
              },
            ]),
            model: {
              value: _vm.selectedDates,
              callback: function ($$v) {
                _vm.selectedDates = $$v
              },
              expression: "selectedDates",
            },
          },
          "date-range-picker",
          _vm.options,
          false
        )
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }