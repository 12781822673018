var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "search-dropdown" },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "click-outside",
              rawName: "v-click-outside",
              value: _vm.blur,
              expression: "blur",
            },
          ],
          staticClass: "search-dropdown__input-container",
          class: {
            "search-dropdown__input-container_open":
              _vm.state.editing && _vm.results.length,
          },
          on: { click: _vm.onInputClick },
        },
        [
          _vm.state.loading
            ? _c("svg", { staticClass: "icon icon-loading" }, [
                _c("use", {
                  attrs: {
                    "xlink:href": "/static/autogen/img/sprite.svg#loading",
                  },
                }),
              ])
            : _vm.selectedResult
            ? [
                _vm.selectedResult.item == "hotel"
                  ? _c("svg", { staticClass: "icon icon-accommodation" }, [
                      _c("use", {
                        attrs: {
                          "xlink:href":
                            "/static/autogen/img/sprite.svg#accommodation",
                        },
                      }),
                    ])
                  : _c("svg", { staticClass: "icon icon-location" }, [
                      _c("use", {
                        attrs: {
                          "xlink:href":
                            "/static/autogen/img/sprite.svg#location",
                        },
                      }),
                    ]),
              ]
            : _c("svg", { staticClass: "icon icon-search" }, [
                _c("use", {
                  attrs: {
                    "xlink:href": "/static/autogen/img/sprite.svg#search",
                  },
                }),
              ]),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model.trim",
                value: _vm.models.query,
                expression: "models.query",
                modifiers: { trim: true },
              },
            ],
            ref: "input",
            staticClass: "search-dropdown__input",
            attrs: { placeholder: _vm.placeholder, type: "text" },
            domProps: { value: _vm.models.query },
            on: {
              input: [
                function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.models, "query", $event.target.value.trim())
                },
                _vm.onInput,
              ],
              blur: function ($event) {
                return _vm.$forceUpdate()
              },
            },
          }),
          _vm._v(" "),
          _vm.state.editing && _vm.models.query
            ? _c(
                "svg",
                {
                  staticClass: "icon icon-close",
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.clearInput($event)
                    },
                  },
                },
                [
                  _c("use", {
                    attrs: {
                      "xlink:href": "/static/autogen/img/sprite.svg#close",
                    },
                  }),
                ]
              )
            : _vm._e(),
        ],
        2
      ),
      _vm._v(" "),
      _c("transition", { attrs: { name: "slide-down", appear: "" } }, [
        _vm.state.editing && _vm.results.length
          ? _c(
              "ul",
              { staticClass: "search-dropdown__results" },
              _vm._l(_vm.convertedResults, function (result) {
                return _c(
                  "li",
                  {
                    staticClass: "search-dropdown-item",
                    on: {
                      mousedown: function ($event) {
                        $event.preventDefault()
                        return _vm.onSearchResultSelect(result)
                      },
                    },
                  },
                  [
                    _c("div", { staticClass: "search-dropdown__icon" }, [
                      result.item == "hotel"
                        ? _c(
                            "svg",
                            { staticClass: "icon icon-accommodation" },
                            [
                              _c("use", {
                                attrs: {
                                  "xlink:href":
                                    "/static/autogen/img/sprite.svg#accommodation",
                                },
                              }),
                            ]
                          )
                        : _c("svg", { staticClass: "icon icon-location" }, [
                            _c("use", {
                              attrs: {
                                "xlink:href":
                                  "/static/autogen/img/sprite.svg#location",
                              },
                            }),
                          ]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "search-dropdown-item__content" },
                      [
                        _c(
                          "div",
                          { staticClass: "search-dropdown-item__header" },
                          [
                            _c(
                              "div",
                              { staticClass: "search-dropdown-item__name" },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(result.name) +
                                    "\n            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            result.type
                              ? _c(
                                  "div",
                                  { staticClass: "search-dropdown-item__type" },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(result.type) +
                                        "\n            "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "search-dropdown-item__description" },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(result.description) +
                                "\n          "
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]
                )
              }),
              0
            )
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }