import { API } from './_common'

export default {
  EVENT_TYPES: {
    CART_ACTIVE: 'cart_active',
    RATE_EXPIRED: 'rate_expired',
  },

  create(data) {
    return API.post('/apis/booking-intents/', data);
  },
  detail(slug) {
    return API.get(`/apis/booking-intents/${slug}/`);
  },
  paymentData(slug) {
    return API.get(`/apis/booking-intents/${slug}/payment-data/`);
  },
  checkDuplicate(slug) {
    return API.post(`/apis/booking-intents/${slug}/check-duplicate/`);
  },
  checkRate(slug) {
    return API.post(`/apis/booking-intents/${slug}/check-rate/`);
  },
  changeCurrency(slug, data) {
    return API.post(`/apis/booking-intents/${slug}/change-currency/`, data);
  },
  validate(slug, data) {
    return API.post(`/apis/booking-intents/${slug}/validate/`, data);
  },
  complete(slug, data) {
    return API.post(`/apis/booking-intents/${slug}/complete/`, data);
  },
  saveInput(data) {
    return API.post('/apis/intent-inputs/', data);
  },
  saveEvent(data) {
    return API.post('/apis/intent-events/', data);
  }  
}
