import Vue from 'vue';
import Vuex from "vuex";

import hotelSearch  from './hotelSearch';
import mapState from "./mapState";
import pdp from "./pdp";
import user from "./user";

Vue.config.devtools = true;

const store = new Vuex.Store({
    modules: {
        hotelSearch: hotelSearch,
        mapState: mapState,
        pdp,
        user,
    }
  })

// Important: All apps except navbar must use store: window.STORE
if(!window.STORE){
  window.STORE = store;
}

export default window.STORE;
