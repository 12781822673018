import Vue from 'vue';
import Page from './vue-apps/pages/home.vue';

new Vue(Page).$mount('.home-page');
Vue.config.devtools = true;

$(document).ready(function() {
  var PAGE_PAYLOAD = {
    event: 'home_load',
    pageType: 'home'
  };
  dataLayer.push(PAGE_PAYLOAD);
});
