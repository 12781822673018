import Hotel from "../api/hotel";

import BookingIntent from "../api/booking_intent";
import Booking from "../api/booking";
import { geometricDistance } from '../../utils/utils.js'



function bComTypeOffer(extLinkAsList){
    const bcomFound = extLinkAsList.find(x=> x.site==='booking.com')
    if (bcomFound){
        if(parseFloat(bcomFound.externalLink.baseRate) > 0){
            return "bcom with price"
        }else{
            return "bcom without price"
        }
    }else{
        return "no bcom offer"
    }
}

import Vue from 'vue'

function get_bed_types(room) {
    //var regex = /^Room size \(sqm\)|^\(King\)-size bed|^\(Queen\) bed/;
    var bed_types = {};
    var regex = /^(King|Queen|Single|Double)(-size)? bed/i;
    if (room["room_stay"] && room["room_stay"]["room_stay_facilities"]) {
      room["room_stay"]["room_stay_facilities"].map((facility, index) => {
        let fac = regex.exec(facility.facility);
        if (fac) {
          if (!bed_types[fac[1]]) {
            bed_types[fac[1]] = 0;
          }
          bed_types[fac[1]] += 1;
        }
      });
    }
    return bed_types;
}

function createPaxesForRate(paxes, room, rate){
    let roomPaxes = []
    $.each(paxes, function(idx, roomGroup){
        let group = {
            rateId: rate["rateId"],
            //rateKey: rate["rateKey"],
            roomIndex: room["roomIdx"],
            roomCode: rate.room_code,
            boardCode: rate.boardCode,
            rooms: 1,
            num_rooms: 1,
            adults: roomGroup.adults,
            //children: roomGroup.children,
        }
        group.children = []
        $.each(roomGroup.children, function (idx, age) {
            group.children.push({'age':age})
        });
        group.children.sort((a, b) => Number(a.age) - Number(b.age));
        roomPaxes.push(group)
    });
    //console.log(roomPaxes);
    return roomPaxes
}
export default {
    namespaced: true,
    state: {
        site: null,
        bookingIntentErrorCode: 1111,
        firstLoad: true,
        roomsLoading: false,
        roomsLoadingFailed: false,
        isBookingInProgress: false,

        autoRedirectEnabled: window.AUTO_REDIRECT_TO_EXTERNAL_SITE_ENABLED,
        minSavingRequiredForRedirect: window.AUTO_REDIRECT_TO_EXTERNAL_SITE_MIN_SAVING_REQUIRED,
        minSavingPercentageRequiredForRedirect: window.AUTO_REDIRECT_TO_EXTERNAL_SITE_MIN_SAVING_PERCENTAGE_REQUIRED,
        numSecondsToWaitBeforeRedirect: parseInt(window.PDP_REDIRECT_SHOW_TIMEOUT),

        autoRedirectTimerStarted: false,
        autoRedirectTimerId: null,
        autoRedirectTimerStartedAt: undefined,
        autoRedirectSecondsLeft: 0,
        autoRedirectPercentageComplete: 0,
        autoRedirectTimerEnded: false,
        autoRedirectNow: false,

        // Update for choose room button
        chooseRoomButtonURL: '#availability',

        //canCancelBooking: false,

        bookingAttemptFailed: false,
        bookingProgressMessage: "",

        roomSearchAbortController: new AbortController(),
        reserveRoomAbortController: new AbortController(),

        cart_url: "",

        check_in: undefined,
        check_out: undefined,
        hotelID: undefined,
        paxes: [{'adults': 2,'children':[]}],
        hotelData: {},
        regions_by_type : {},
        nearbyHotels: [],
        disabledRooms: {},
        rooms: [],
        roomKeys: {},
        rateInfo: {},
        minRoomRate: {},
        preloadedExternalLinks: [],
        externalLinks: {},
        selectedRoom:undefined,
        selectedRateId:undefined,
        from_landing:'',
        selectedCurrency:'USD',

    },
    actions: {
        setupAutoRedirectTimer({state, commit}){
            if(state.autoRedirectEnabled && !state.autoRedirectTimerStarted && !state.autoRedirectTimerEnded){
                commit('startAutoRedirectTimer')
                const id = setInterval(() => {
                    // Call your mutation here, pass any payload you need
                    commit('updateAutoRedirectTimer');
                  }, 100); // Call the mutation every 300 ms
            }else if(state.autoRedirectTimerStarted){
                commit('endAutoRedirectTimer')
            }
        },
        updateRoomSearchQuery({state, commit}, {hotelID, check_in, check_out, accommodation}){
            commit('setPaxes', accommodation);
            commit('setHotelID', hotelID);
            commit('setHotelID', hotelID);
            commit('setHotelID', hotelID);
            commit('setCheckIn', check_in);
            commit('setCheckOut', check_out);
        },
        switchSelectedRate({state, commit}, {roomCode, rateId}) {
            //console.log([roomCode, rateId]);
            return new Promise((resolve, reject) => {
                let rate;
                for (var i = 0; i < state.rooms.length; i++) {
                    let room = state.rooms[i];
                    if (room.code === roomCode) {
                        rate = room.rates.find((x) => x.rateId === rateId);
                        if(rate){
                            break;
                        }
                    }
                }
                if(rate){
                    commit('switchRate', {roomIdx:i, rate:rate});
                    resolve(rate);
                }
                reject();
            });
            
        },
        reserveRoom({dispatch, commit, state, getters}, rateId) {
            let rate = state.rateInfo[rateId];
            let room = getters.roomsByCode[rate.room_code];
            let rateSearchParams = {...getters.hotelParams}
            rateSearchParams.signal = state.reserveRoomAbortController.signal;
            let accomodations = createPaxesForRate(state.paxes, room, rate);

            let bookingParams = {
                accommodation: accomodations,
                hotel: state.hotelData.code,
                checkin: state.check_in,
                checkout: state.check_out,
            };
            if(state.bookingIntent){
                bookingParams.bookingIntentId = state.bookingIntent.id;
            }

            return new Promise((resolve, reject) => {
                commit('startRoomBookingAttempt', bookingParams);
                //this.makeReservation($event, item.room.code, { ...rateSearchParams });

                commit('setRoom', rate.room_code);
                commit('setRateId', rate.rateId);

                submitLogDL_GA_plus_DB('pdp_reserveRoom', 'room_picker', {hotelId: state.hotelID , roomCode: rate.room_code, rateId: rate.rateId})

                
                // window.dataLayer.push({ ecommerce: null });
                // window.dataLayer.push({
                //   event: "eec.add",
                //   ecommerce: {
                //     add: {
                //       products: [
                //         {
                //           id: self.hotelID,
                //           name: self.hotelData.name,
                //         },
                //       ],
                //     },
                //   },
                // });
                // // new cart
                // // BookingIntent.create(payload)
                Booking.create(bookingParams, state.reserveRoomAbortController.signal)
                    .catch((error) => {
                        //console.log([error, 'sdfsdfsdf']);
                        if (!axios.isCancel(error)) {

                            state.isBookingInProgress = false;
                            // commit('setBookingAttemptError', {error, rateSearchParams});
                            // error.response.data.error_code = 30002; //simulation for user-nlocked error
                            // console.log({error: error.response.data})
                            reject(error);
                        }else{
                            commit('cancelReservationAttempt');
                        }
                    }).then((response) => {
                        commit('setBookingAttemptSuccess', {response, rateSearchParams});
                        resolve(state.cart_url);
                    });
            });
        },
        getHotelDetail({commit, state, getters, dispatch}) {
            let self = this;
            //let paxes = state.paxes;
            if(state.roomsLoading){
                commit('cancelRoomSearch');
                //console.log('canceling room search')
            }
            commit('startRoomsSearch');
            return new Promise((resolve, reject) => {
                Hotel.detail(state.hotelID, getters.hotelParams, state.roomSearchAbortController.signal)
                    
                 .then((response) => {
                        //begin simulation
                        // .then((resp) => {
                        // var simulation = require('../../../../aux_js_scripts/hd_with_isAdRate_simpl.json')
                        // let response = {data:0}
                        // response.data = simulation
                        // end simulation

                        commit('endRoomsSearch');
                        if(response.data.rooms){
                            commit('setHotelData', response.data);
                            response.data.rooms.map((room, roomIdx) => {
                                if (room.rates && room.rates.length > 0) {
                                    commit('addRoom', room);
                                }
                            });
                        }else{
                            console.log("NO ROOMS")
                        }
                        // console.log('bobo', JSON.stringify(response.data))
                        if(response.data.autoRedirect && response.data.autoRedirect.placement == 'choose_room' && response.data.autoRedirect.link){
                            commit('updateChooseRoomLink', response.data.autoRedirect.link);
                        }
                        commit('setExternalLinks', response.data.externalLinks);
                        if(response.data.sortOrder){
                            commit('setupSortOrder', response.data.sortOrder);
                        }
                        
                        
                        // self.state.isLoading = false;
                        // self.$nextTick(function () {
                        //     if (!this.state.mapLoaded) {
                        //         initMap(self.hotelData.latitude, self.hotelData.longitude, self.hotelData.name);
                        //         this.state.mapLoaded = true;
                        //     }
                        // });
                        // document.title = self.hotelData.name;
                        //self.externalLinks = response.data.externalLinks;
                        //self.externalLinksRoomName = undefined;
            
                        if (self.rooms && self.rooms.length > 0) {
                            let roomIdx = response.data.minRoomRate.roomIdx;
                            let rateIdx = response.data.minRoomRate.rateIdx;
                            commit('setMinRoomRate', self.rooms[roomIdx].rates[rateIdx]);
                            
                            /*self.rooms = self.rooms.map((room, roomIdx) => {
                                var rate = room.rates[0];
                                room.usedCount = 0;
                                var regex = /^Room size \(sqm\)/;
                                var room_size_facility = room.facilities.find((facility, index) => {
                                    return regex.exec(facility.facility);
                                });
                                if (room_size_facility) {
                                    room["room_size"] = room_size_facility["number"];
                                }
                                
                                //if(roomExternalLinks && !self.externalLinks){
                                //  self.externalLinks = roomExternalLinks;
                                //  self.externalLinksRoomName = room['name'];
                                //}
                                room.rates.sort(
                                (a, b) => Number(a.baseRate) - Number(b.baseRate)
                                );
                                room.rate = rate;
                                return room;
                            });*/
                        } else {
                        }
                        if(getters.conditionShowExtOfferMet){
                            dispatch('setupAutoRedirectTimer');
                        }
                        resolve();
                    })
                    .catch((error) => {
                        this.state.searchError = true;
                        if(error.message != 'canceled'){
                            commit('setRoomsLoadingFailed', error); //roomsLoadingFailed
                        }
                        reject(error);
                    });
                })
            },
            getNearbyHotels({commit, state, getters}) {
                return new Promise((resolve, reject) => {
                    Hotel.nearby(state.hotelID, getters.hotelParams).then((response) => {
                        let nearbyArray = response.data

                        for(const nearbyHotel of nearbyArray){
                            nearbyHotel.distance_to_pdp = geometricDistance(state.hotelData.longitude, state.hotelData.latitude,
                                                        nearbyHotel.longitude, nearbyHotel.latitude);
                        }

                        commit('setNearbyHotels', nearbyArray.sort((a,b) => a.distance_to_pdp - b.distance_to_pdp));
                    });
                    resolve();
                }).catch((e) => {
                    console.log(e);
                });
            },
    },
    getters: {

        sortOrderSummaryEnhanced(state, getters){
            if (state.roomsLoading===false && state.hotelData.sortOrderSummary) {
                const sortOrderSummary = JSON.parse(JSON.stringify(state.hotelData.sortOrderSummary));
                const combinedExternalInternalRooms = getters.combinedExternalInternalRooms;


                for (const elSearch of sortOrderSummary) {

                    if (elSearch.type === 'ext_link') {
                        const elFound = combinedExternalInternalRooms.find(x => x.site === elSearch.ext_link_name)
                        // console.log({[elSearch.ext_link_name]:elFound})
                        elSearch.name = elFound["externalLink"]["roomName"]
                        elSearch.baseRate = parseFloat(elFound["externalLink"]["baseRate"] / getters.qNights)
                        elSearch.chargeAmountTotalNights = parseFloat(elFound["externalLink"]["chargeAmount"]) //  / getters.qNights
                        elSearch.isRefundable = elFound["externalLink"]["isRefundable"]
                        elSearch.isBreakfastIncluded = elFound["externalLink"]["isBreakfastIncluded"]

                        elSearch.link = elFound["externalLink"]["link"]
                    } else {
                        const cheapestInternalRate = state.hotelData.rooms[elSearch.index];
                        elSearch.type = 'internal'
                        elSearch.ext_link_name = state.site;

                        elSearch.name = cheapestInternalRate.name;
                        elSearch.baseRate = parseFloat(cheapestInternalRate["rates"][0].baseRate / getters.qNights)
                        elSearch.chargeAmountTotalNights = parseFloat(cheapestInternalRate["rates"][0].chargeAmount) //  / getters.qNights

                        elSearch.isRefundable = cheapestInternalRate["rates"][0]["isRefundable"]
                        elSearch.isBreakfastIncluded = cheapestInternalRate["rates"][0]["isBreakfastIncluded"]
                        elSearch.link = "#availability"
                    }

                }

                return sortOrderSummary;

            }
        },

        getBookingIntentErrorCode(state){
            return state.bookingIntentErrorCode;
        },
        roomsAvailable(state){
            return state.rooms.length > 0;
        },

        autoRedirectConditions(state, getters){
            return {
                params: {
                    autoRedirectEnabled: state.autoRedirectEnabled,
                    minSavingRequiredForRedirect: state.minSavingRequiredForRedirect,
                    minSavingPercentageRequiredForRedirect: state.minSavingPercentageRequiredForRedirect,
                    numSecondsToWaitBeforeRedirect: state.numSecondsToWaitBeforeRedirect,
                },
                savingsOffered: {...getters.savingsBestExtProviderVsBestInternalOffer}
                
            }
        },
        conditionShowExtOfferMet(state, getters){
            // the modal to show ext redirect will be shown only
            // if the cheapes offer is an external one
            // todo: see if this logic applies with 4 variants
            // const cheapestOffer = getters.cheapestRate
            // if (state.autoRedirectEnabled && !state.autoRedirectTimerEnded && cheapestOffer && 'externalLink' in cheapestOffer) {
            //     const savings = getters.savingsBestExtProviderVsBestInternalOffer
            //     if (savings.amountSavedUSD > state.minSavingRequiredForRedirect ||
            //             savings.percentageSaved > state.minSavingPercentageRequiredForRedirect ){
            //         return true
            //     }
            //
            // }
            // return false
            if (getters.pdpRedirectVariants > 0 && getters.pdpRedirectVariants <= 4){
                return true;
            }else{
                return false;
            }

        },

        savingsBestExtProviderVsBestInternalOffer( state, getters){
            if(state.autoRedirectEnabled && getters.cheapestRate && 'externalLink' in getters.cheapestRate){
                if(getters.cheapestInternalRate && getters.cheapestRate.externalLink.baseRate){
                    const cheapestOfferUSD =  parseFloat(getters.cheapestRate.externalLink.baseRate);
                    const cheapestIntOfferUSD = parseFloat(getters.cheapestInternalRate.baseRate);
                    const cheapestOffer =  parseFloat(getters.cheapestRate.externalLink.baseRate);
                    const cheapestIntOffer = parseFloat(getters.cheapestInternalRate.baseRate);
                    const amountSaved = cheapestIntOffer - cheapestOffer
                    const amountSavedUSD = cheapestIntOfferUSD - cheapestOfferUSD
                    const percentageSaved = amountSaved / cheapestIntOffer * 100
                    return {percentageSaved, 
                            amountSaved,
                            amountSavedUSD,
                            cheapestOffer,
                            cheapestIntOffer: getters.cheapestInternalRate,
                            cheapestProvider: getters.cheapestRate.site,
                            link:  getters.cheapestRate.externalLink.link,
                            logo: getters.cheapestRate.externalLink.logo}
                }
            }
            return {link:  getters.cheapestRate.externalLink.link,
                            logo: getters.cheapestRate.externalLink.logo}

        },
        
        qNights(state){
            const check_in = new Date(state.check_in);
            const check_out = new Date(state.check_out);
            const diffTime = Math.abs(check_out - check_in);
            const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
            
            return diffDays;
          },
        selectedCurrency(state, getters, rootState){
            return rootState.user.selectedCurrency;
        },
        getPreloadedExternalLinks(state){
            return state.preloadedExternalLinks;
        },
        show_onetap(state){
            return state.rooms.length > 0;
        },
        hasCheckInDefined(state){
            try {
                return state.hotelData.check_in_time_range.start !== null || state.hotelData.check_in_time_range.end !== null;
            } catch (error) {
                return false;
            }
        },
        hasCheckOutDefined(state){
            try {
                return state.hotelData.check_out_time_range.start !== null || state.hotelData.check_out_time_range.end !== null;
            } catch (error) {
             return false;   
            }
        },
        neighborhood_segments(state){
            return state.regions_by_type.neighborhood;
        },
        is_cart_ready(state) {
            return state.cart_url.length > 0;
        },
        basic_facilities(state) {
            var facs = [];
            var regex = /^(Wi-fi)/i;
            if (!state.hotelData["top_facilities"]) {
              return facs;
            }
            state.hotelData["top_facilities"].map((facility, index) => {
              let match = regex.exec(facility["name"]);
              if (match) {
                if (match[1] == "Wi-fi") {
                  facs.push("Free Wifi");
                } else {
                  facs.push(match[1]);
                }
              }
              if (facs.length == 2) {
                return;
              }
              return facility;
            });
            return facs;
        },
        selectedPaxes(state, getters){
            return {adults:getters.totalAdultsNumber, children:getters.totalChildrenNumber, rooms: getters.totalRoomsNumber}
        },
        totalRoomsNumber(state) {
            return state.paxes.length
        },
        totalGuestsNumber(state, getters) {
            return getters.totalAdultsNumber + getters.totalChildrenNumber;
        },
        totalAdultsNumber(state) {
            return state.paxes.reduce(
                (guestsNum, room) => Number(guestsNum) + Number(room.adults), 0
            );
        },
        totalChildrenNumber(state) {
            return state.paxes.reduce(
                (guestsNum, room) => Number(guestsNum) + Number(room.children.length), 0
            );
        },
        numNights(state) {
            return moment(state.check_out, "YYYY-MM-DD").diff(
              moment(state.check_in, "YYYY-MM-DD"),
              "days"
            );
        },
        roomDiscount(state, getters) {
            if (state.minRoomRate) {
              let rate = state.minRoomRate;
              if (rate.baseRate && rate.strikeThroughPrice) {
                return Math.round(
                  (1 -
                    parseFloat(this.rate.baseRate) /
                      parseFloat(
                        this.currencyAdjustedAmount(baseRate, "strikeThroughPrice")
                      )) *
                    100
                );
              }
            }
            return 6;
        },
        isRoomDisabled(state, roomCode) {
            if (!state.disabledRooms[roomCode]) {
              return false;
            }
            return true;
        },
        pdpRedirectVariants(state, getters){
            // 1. cheapest is booking and internal is present
            // 2. cheapest is booking, internal is NOT present, there are other ext providers besides booking
            // 3. booking is the only price
            // 4. no prices known

            let variantToReturn = 0;
            let _bcomOfferTypeVar = bComTypeOffer(getters.externalLinksAsList)
            let cheapesSite = getters.cheapestRate && getters.cheapestRate.site
            let internalOfferPresent = false;
            if (getters.cheapestInternalRate && Object.keys(getters.cheapestInternalRate).length > 0){
                internalOfferPresent = true;
            }
            let countExtOffers = getters.externalLinksAsList.length;

            if(cheapesSite=== "booking.com" && internalOfferPresent===true && getters.savingsBestExtProviderVsBestInternalOffer.percentageSaved > 5){
                variantToReturn = 1;
            }else if (cheapesSite=== "booking.com" && internalOfferPresent===false) {
                if (countExtOffers > 1) {
                    variantToReturn = 2;
                } else {
                    variantToReturn = 3;
                }
            }

            if (_bcomOfferTypeVar==="bcom without price"){
                variantToReturn = 4
            }


            console.log({variantToReturn, cheapesSite, internalOfferPresent, countExtOffers})

            return variantToReturn

        },

        combinedExternalInternalRooms(state, getters){
            let combinedList = [...getters.externalLinksAsList];
            combinedList.push(...state.rooms);
            combinedList.sort((a, b) => {
                let diff = a.sortIndex - b.sortIndex
                // if(diff == 0){
                //     diff = a.site_weight - b.site_weight
                // }
                return diff;
            });
            // console.log(combinedList);
            return combinedList;
        },
        // roomKeys(state, getters){
        //     let roomKeys = [];
        //     getters.combinedExternalInternalRooms.map((room, roomIdx) => {
        //         roomKeys.push(room.key);
        //     });
        //     return roomKeys;
        // },
        cheapestRate(state, getters){
            let rates = [...getters.combinedExternalInternalRooms];
            let rateToReturn = undefined;
            for (var i = 0; i < rates.length; i++) {
                if(!isNaN(rates[i].min_price)){
                    // console.log(['sdsdsd', rates[i], rates[i].min_price])
                    // return rates[i]
                    rateToReturn = rates[i]
                    break;
                }
            }
            // added this because it's possible that all offers has no price
            // so in that case first rate should be the one returned (variant 4)
            if (rateToReturn===undefined){
                rateToReturn = rates[0]
            }
            return rateToReturn
        },
        compactedRoomOptionsByPassengerMix(state, getters) {
            var rooms = [];
            for (var i = 0; i < state.rooms.length; i++) {
              let room = state.rooms[i];
              let roomTypes = { room: room, roomTypes: {} };
              //if(typeof rooms[room.code] == 'undefined'){
              //    rooms[room.code] = ;
              //}
              if(room.rates.length > 0){
                const min_price = Number(room.rates[0].baseRate)
                roomTypes.min_price = min_price
                roomTypes.sort_price = min_price
                roomTypes.site_weight = 2
              }
              for (var y = 0; y < room.rates.length; y++) {
                var rate = room.rates[y];
                if (rate && rate.adults) {
                  var roomType = `${rate.adults} Adults | ${rate.children} Children`;
                  //roomTypes[roomType] = true;
                  if (typeof roomTypes["roomTypes"][roomType] == "undefined") {
                    roomTypes["roomTypes"][roomType] = [];
                  }
                  //if (!rate.packaging) {
                  //  roomTypes["roomTypes"][roomType].push(rate);
                  //}
                  roomTypes["roomTypes"][roomType].push(rate);
                }
              }
              rooms.push(roomTypes);
            }
            return rooms;
        },
        cheapestInternalRate(state){
            const rooms = state.rooms;
            if (rooms) {
                let cheapestIntRate = +Infinity;
                let cheapestIntRateObject = {}
    
                for (let i = 0; i < rooms.length; i++) {
                    let room = rooms[i];
                    // console.log(room);
                    for (let j = 0; j < room.rates.length; j++) {
                        let rate = room.rates[j];
                        let baseRate_j = parseFloat(rate.baseRate);
                        if (baseRate_j < cheapestIntRate) {
                            cheapestIntRate = baseRate_j;
                            cheapestIntRateObject = rate;
                            cheapestIntRateObject.name = room.name;
                            // console.log(`NEW CHEAPEST: ROOM ${i} / RATE ${j}`);
                        }
                        
                    }
                    
                }
    
                return cheapestIntRateObject;
            }else{
                return null;
            }
            
        },

        // cheapestRate(state, getters) {
        //     for (var i = 0; i < getters.combinedExternalInternalRooms.length; i++) {
        //         let rate = getters.combinedExternalInternalRooms[i];
        //         if(rate.min_price){
        //             return rate;
        //         }
        //     }
        // },
        sortedRooms(state, getters) {

        },
        roomsByCode(state) {
            return Object.fromEntries(state.rooms.map((room, roomIdx) => {
                // let roomWithIndex = {...room};
                // roomWithIndex.roomIdx = roomIdx;
                return [String(room.code), room]
            }));
        },
        externalLinksAsList(state, getters) {
            const externalSiteWeights = {
                'booking.com' : 1,
            }
            let exLinks = [];
            $.each(state.externalLinks, (site, externalLink) => {
                const min_price = 0
                const site_weight = externalSiteWeights[site.toLowerCase()] || 2;
                const sort_price = externalLink.baseRate || 0
                const sort_index = externalLink.sortIndex || 0

                externalLink.logo = `/static/img/ext-logos/${site.toLowerCase()}.svg`;
                exLinks.push({ site: site, externalLink: externalLink,
                                'min_price': Number(externalLink.baseRate),
                                'sort_price': sort_price,
                                'sortIndex': sort_index,
                                'key': externalLink.link,
                                'site_weight': site_weight});
            });
            return exLinks;
        },

        hotelParams(state) {
            var self = this;
            let searchParams = {};
        
            searchParams.check_in = state.check_in;
            searchParams.check_out = state.check_out;
            searchParams.rooms = state.paxes.length
            searchParams.from_landing = state.from_landing;
            searchParams.currency = state.selectedCurrency;
        
            $.each(state.paxes, function (idx, roomGroup) {
                let ages = [];
                searchParams[`room${idx}_adults`] = roomGroup.adults;
                if(roomGroup.children.length){
                    searchParams[`room${idx}_children`] = roomGroup.children.length
                }
                searchParams[`room${idx}_children`] = 0;
                $.each(roomGroup.children, function (idx, age) {
                    ages.push(age);
                });
                if(ages.length){
                    searchParams[`room${idx}_children_ages`] = ages.join(",");
                }
            });
            return searchParams;
        },
        isValidRoomSearch(state, getters) {
            let minimumCheckinDate = moment().subtract(1, "days").startOf("day");
            //console.log([this.checkInDate,this.checkOutDate, minimumCheckinDate, this.startDate, this.endDate]);
            if (
                state.check_in &&
                state.check_out &&
                getters.checkOutDate.isAfter(getters.checkInDate) &&
                getters.checkInDate.isSameOrAfter(minimumCheckinDate)
            ) {
                //console.log('valid');
                return true;
            }
            //console.log('invalid');
            //console.log(minimumCheckinDate.isBefore(this.checkInDate));
            return false;
        },
        earliestDatePossible() {
            let earliestDate = new Date();
            // If earlier than 5AM allow previous day search
            if (earliestDate.getHours() < 5) {
                earliestDate.setDate(earliestDate.getDate() - 1);
            }
            return moment(earliestDate);
        },
        earliestCheckoutDatePossible(state, getters) {
            let earliestDate = getters.earliestDatePossible;
            let checkInDate = getters.checkInDate;
            if(checkInDate.isSameOrBefore(earliestDate)){
                return moment(earliestDate.add(1, 'days'));
            }
            return moment(checkInDate.add(1, 'days'));
        },
        checkInDate(state, getters) {
            if (state.check_in) {
              return moment(state.check_in, "YYYY-MM-DD");
            }
            return moment(getters.earliestDatePossible);
        },
        checkOutDate(state, getters) {
            if (state.check_out) {
                return moment(state.check_out, "YYYY-MM-DD");
            }
            return moment(getters.checkInDate).add(1, "days");
        },
        noDatesGiven(state) {
            if (state.check_in && state.check_out) {
              return false;
            }
            return true;
        },
        
    },
    
    mutations: {
        setSite(state, payload){
            state.site = payload
        },
        updateChooseRoomLink(state, link){
            state.chooseRoomButtonURL = link;
        },
        setBookingIntentErrorCode(state, payload){
            console.log("setBookingIntentErrorCode, ", payload)
            state.bookingIntentErrorCode = payload
            state.bookingAttemptFailed = true;
        },
        startAutoRedirectTimer( state){
            if(!state.autoRedirectTimerEnded){
                state.autoRedirectTimerStarted = true;
                state.autoRedirectTimerStartedAt = Math.floor(Date.now());
            }
        },
        setAutoRedirectTimerID(state, timer_id){
            state.autoRedirectTimerId = id
        },
        updateAutoRedirectTimer(state){
            if(state.autoRedirectEnabled && state.autoRedirectTimerStarted && !state.autoRedirectTimerEnded){
                const timeElapsed = Date.now() - state.autoRedirectTimerStartedAt;
                const timeLeft = (state.numSecondsToWaitBeforeRedirect * 1000) - timeElapsed
                if(timeLeft >0 ){
                    state.autoRedirectSecondsLeft = Math.floor(timeLeft / 1000)
                    state.autoRedirectPercentageComplete = 1 - timeLeft / (state.numSecondsToWaitBeforeRedirect * 1000) 
                }else{
                    state.autoRedirectSecondsLeft = 0
                    state.autoRedirectPercentageComplete = 1
                    state.autoRedirectTimerStarted = false;
                    state.autoRedirectTimerEnded = true;
                    state.autoRedirectNow = true;
                    clearInterval(state.autoRedirectTimerId)
                }
            }
        },
        changeAutoRedirectTimerStartedAt(state){
            state.autoRedirectTimerStartedAt = Math.floor(Date.now());
        },
        endAutoRedirectTimer(state){
            state.autoRedirectTimerStarted = false;
            state.autoRedirectTimerEnded = true;
            state.autoRedirectSecondsLeft = 0
            state.autoRedirectPercentageComplete = 1
            state.autoRedirectNow = false;
            clearInterval(state.autoRedirectTimerId)
            
        },
        setPreloadedExternalLinks(state, {error, preloadedExternalLinks}){
            state.preloadedExternalLinks = preloadedExternalLinks;
        }, //
        setRoomsLoadingFailed(state, error){
            state.roomsLoadingFailed = true;
            console.log(error);
        }, //
        switchRate(state, {roomIdx, rate}) {
            //console.log([roomIdx, {...rate}]);
            Vue.set(state.rooms[roomIdx], "rate", rate);
            Vue.set(state.rooms[roomIdx], "selectedRateId", rate.rateId);
            Vue.set(state.rooms[roomIdx], "key", rate.rateId);
            // state.rooms[roomIdx].rate = rate;
            // state.rooms[roomIdx].selectedRateId = rate.rateId;
            // state.rooms[roomIdx].key = rate.rateId;
            // state.roomKeys[state.rooms[roomIdx].code] = rate.rateId;
            Vue.set(state.roomKeys, state.rooms[roomIdx].code, rate.rateId);
        },
        setBookingAttemptSuccess(state, {response, payload}){
            //state.canCancelBooking = false;
            if (response && state.isBookingInProgress == true) {
                submitLogDL_GA_plus_DB('requestReservationSuccess', 'room_picker', { payload, response });
                state.cart_url = response.data["url"];
            }
        },
        setBookingAttemptError(state, {error, rateSearchParams}){
            state.bookingAttemptFailed = true;
            //state.canCancelBooking = true;
            state.bookingProgressMessage =
                "Sorry, we were not able to reserve this room due to a network error. <br/><br/> Please try again later";
            if (
                typeof error.response === "object" &&
                typeof error.response.data === "object" &&
                error.response.data.message
            ) {
                state.bookingProgressMessage = error.response.data.message;
                if (error.response.data.error_code == "PRODUCT_ERROR") {
                    state.bookingProgressMessage = "Sorry, this room is no longer available. Please try another room";
                    state.disabledRooms[roomCode] = true;
                }
            }
            submitLogDL_GA_plus_DB('requestReservationFailed', 'room_picker', { rateSearchParams, error});
            
        },
        cancelReservationAttempt(state) {
            if (state.reserveRoomAbortController) {
              state.reserveRoomAbortController.abort();
            }
            state.isBookingInProgress = false;
            state.bookingProgressMessage = "";
            state.bookingAttemptFailed = false;
            //state.canCancelBooking = true;
            submitLogDL_GA_plus_DB('cancelReservationClicked', 'room_picker', {});
        },
        setRegionData(state, regions_by_type){
            //let self = this;
            
            $.each(regions_by_type, (reg_type, regions) => {
              $.each(regions, (idx, region) => {
                region["region_dist"] = pointDistance(
                  state.hotelData.latitude,
                  state.hotelData.longitude,
                  region.lat,
                  region.long
                );
              });
            });
            $.each(regions_by_type, (reg_type, regions) => {
              state.regions_by_type[reg_type] = regions.sort(
                (a, b) => Number(a.region_dist) - Number(b.region_dist)
              );
            });
        },
        startRoomsSearch(state){
            state.rooms = [];
            state.externalLinks = {};
            state.roomsLoading = true;
            state.bookingAttemptFailed = false;
            state.isBookingInProgress = false;
            state.roomsLoadingFailed = false;
            state.roomSearchAbortController = new AbortController();
        },
        startRoomBookingAttempt(state, payload){
            state.loadingMessage = "Please wait...";
            state.isBookingInProgress = true;
            //state.canCancelBooking = true;
            state.bookingProgressMessage = "Please wait while we reserve your room";
            state.bookingAttemptFailed = false;
            state.reserveRoomAbortController = new AbortController();
            submitLogDL_GA_plus_DB('requestReservation', 'room_picker', { payload: payload });
        },
        endRoomsSearch(state){
            state.roomsLoading = false;
        },
        cancelRoomSearch(state) {
            state.roomSearchAbortController.abort();
            state.roomsLoading = false;
        },
        addRoom(state, room){
            //state.rooms.push(room);            
            var regex = /^Room size \(sqm\)/;
            var room_size_facility = room.facilities.find((facility, index) => {
                return regex.exec(facility.facility);
            });
            if (room_size_facility) {
                room["room_size"] = room_size_facility["number"];
                room["room_size_sqft"] = (Number(room["room_size"]) * 10.76391).toFixed(0);
            }
            
            //if(roomExternalLinks && !self.externalLinks){
            //  self.externalLinks = roomExternalLinks;
            //  self.externalLinksRoomName = room['name'];
            //}
            room.rates.sort(
                (a, b) => Number(a.baseRate) - Number(b.baseRate)
            );
            if(room.rates.length > 0){
                let rate = room.rates[0];
                room['min_price'] = Number(room.rates[0].baseRate);
                room['room_discount'] = 0;
                room.rate = room.rates[0];
                room.selectedRateId = room.rate.rateId;
                state.roomKeys[room.code] = room.selectedRateId;
                room.key = room.rate.rateId;
                room.bed_types = get_bed_types(room);
            }
            room.roomIdx = state.rooms.length;
            if(room.rates.length > 0){
                const min_price = Number(room.rates[0].chargeAmount)
                room.min_price = min_price
                room.sort_price = min_price
                room.sortIndex = Infinity;
                room.site_weight = 2
            }
            
            room.rates.map((rate, rateIdx) => {
                let passenger_mix;
                if(!rate["adults"]){
                    rate["adults"] = 2;
                }
                if(!rate["children"]){
                    rate["children"] = 0;
                }
                if (rate["adults"] > 1) {
                    passenger_mix = rate["adults"] + " adults";
                } else {
                    passenger_mix = rate["adults"] + " adult";
                }
                if (rate["children"] == 1) {
                    passenger_mix += " 1 child";
                } else if (rate["children"] > 1) {
                    passenger_mix += rate["children"] + " children";
                }
                let strikeThroughPrice = parseFloat(rate.strikeThroughPrice);
                if (rate.baseRate && strikeThroughPrice > 0) {
                    rate.rate_discount = Math.round(
                      (1 -
                        parseFloat(rate.baseRate) /
                          parseFloat(
                            strikeThroughPrice)
                          ) *
                        100
                    );
                }
                // rate.rate_discount = 15.0
                if (!rate.boardName) {
                    if (rate.boardCode == "RO") {
                        rate.boardName = "Room Only";
                    } else if (rate.boardCode == "BB") {
                        rate.boardName = "Breakfast Included";
                    } else if (rate.boardCode == "HB") {
                        rate.boardName = "Half Board";
                    } else if (rate.boardCode == "FB") {
                        rate.boardName = "Full Board";
                    } else if (rate.boardCode == "AI") {
                        rate.boardName = "All Inclusive";
                    } else {
                        rate.boardName = rate.boardCode;
                    }
                }
                //rate.roomIdx = roomIdx;
                rate.room_code = room.code
                rate.passengerMix = passenger_mix;
                state.rateInfo[rate["rateId"]] = rate;
            });
            state.rooms.push(room);
        },
        setHotelID(state, hotelID){
            state.hotelID = hotelID;
        },
        setCurrency(state, currency){
            state.selectedCurrency = currency;
        },
        setMinRoomRate(state, minRoomRate){
            state.minRoomRate = minRoomRate;
        },
        setExternalLinks(state, externalLinks){
            state.externalLinks = externalLinks;
        },
        setupSortOrder(state, sortOrder){
            Array.from(sortOrder).forEach((room, sortIndex) => {
                if(room.type == "ext_link" && state.externalLinks[room["ext_link_name"]]){
                    state.externalLinks[room["ext_link_name"]].sortIndex = sortIndex;
                }else if(room.type == "room" && state.rooms[room.index]){
                    state.rooms[room.index].sortIndex = sortIndex;
                }
            });
        },
        setCheckIn(state, payload){
            // console.log("setCheckIn");
            state.check_in = payload;
        },
        setHotelData(state, payload){
            // console.log("setCheckIn");
            state.hotelData = payload;
            //state.hotelID = payload.code;
        },
        setCheckOut(state, payload){
            // console.log("check_out");
            state.check_out = payload;
        },
        setPaxes(state, payload){
            state.paxes = payload;
        },
        setRoom(state, payload){
            state.selectedRoom = payload;
        },
        setNearbyHotels(state, hotels){
            state.nearbyHotels = hotels;
        },
        setRateId(state, payload){
            state.selectedRateId = payload;
        },
    }
}