<template>
    <div v-if="selfShow" class="consent-cookie-container">


            <h2 class="consent-cookie-container__title GFont_6_Bold">This website use cookies</h2>
            <!-- -->
            <div @click="selfShow=false" class="consent-cookie-container__close-button">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="https://www.w3.org/2000/svg">
                    <path d="M12 4L4 12" stroke="#1E1C2A" stroke-width="1.75" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M4 4L12 12" stroke="#1E1C2A" stroke-width="1.75" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </div>

            <div class="consent-cookie-container__description GFont_9">
                We use cookies to give you the best experience on our website and also for analytics purposes. 
                
                <span 
                v-show="!expandedForm"
                @click="adjustSettings"
                class="adjust-settings">Adjust settings</span>
                
            </div>

            <div v-show="expandedForm" 
            v-for="(cookieType, index) in cookieTypesArray" :key="index" class="input-container GFont_9">
                <input type="checkbox" 
                @click="clickedInCheckbox"                
                :id="cookieType" :name="cookieType" :value="cookieType" v-model="checkedCookieTypesArray"/>
                <label :for="cookieType">{{ cookiesNameArray[index] }}</label>
            </div>


            <div class="accept-decline-container GFont_8">
                <button v-on="atLeastOneCheckboxChecked ? {click: acceptAll} : {click: acceptAll}" class="accept"
                
                :style="backgroundColorObjectSiteName"
                >Accept all</button>
                <button v-show="expandedForm" @click="accept" 
                    :disabled="!atLeastOneCheckboxChecked"
                    :style="[!atLeastOneCheckboxChecked ? {'cursor':'not-allowed'} : {'cursor':'pointer'}]"
                >Save selection</button>
                <button  @click="declineAll">Deny</button>
                <button v-show="expandedForm" >No, adjust</button>

            </div>
 
    </div>
</template>

<script>
// const COOKIES_TYPE = ["Necessary", "Performance", "Advertising", "Functional"]

//todo: make this a dict/object and simplify
const COOKIES_NAME_MODAL = ["Ad Storage", "Ad User Data", "Ad Personalization", "Analytics Storage"]
const COOKIES_TYPE = ['ad_storage', 'ad_user_data', 'ad_personalization', 'analytics_storage',]

function arraysEqual(a, b) {
  if (a === b) return true;
  if (a == null || b == null) return false;
  if (a.length !== b.length) return false;

  const aSorted = a.sort();
  const bSorted = b.sort();

  for (var i = 0; i < aSorted.length; ++i) {
    if (aSorted[i] !== bSorted[i]) return false;
  }
  return true;
}

export default {

    props:{
        siteName: { type: String, required: false }
    },
    data() {
        return {
            selfShow: undefined,
            cookieTypesArray: COOKIES_TYPE,
            cookiesNameArray: COOKIES_NAME_MODAL,
            checkedCookieTypesArray: [],
            // clickedAtLeastOneParticularCheckbox: false,
            acceptButtonLabel: 'Accept',
            expandedForm: false,
            backgroundColorObject:{
                mobytrip: {'background-color':'#0064D3'},
                nextdayhotels: {'background-color':'#333b53'},
                goodhotelclub: {'background-color':'#dd5d00'},
                reservationlodge: {'background-color':'#e25151'},                
            }
        }
    },
    mounted(){
        // console.log("MOUNTED COOKIE CONSENT MODAL")
        this.checkCookie();
        this.checkedCookieTypesArray = COOKIES_TYPE
    },
    computed:{

        atLeastOneCheckboxChecked(){
            return this.checkedCookieTypesArray.length > 0;
        },
        backgroundColorObjectSiteName(){
            return this.backgroundColorObject[this.$props.siteName];
        },
    },
    watch: {
      selfShow:{
        handler: function (val, oldVal){
          const closed = val===false && oldVal===true
          if(closed){
            if(this.checkedCookieTypesArray.includes("analytics_storage")){
              // window.clarity('consent');
              const timeoutID = setInterval(() => {
                try {
                  window.clarity('consent');
                  clearTimeout(timeoutID)
                  console.log("WINDOWS CLARITY CONSENTED FROM MODAL")
                } catch (err) {
                  console.log(err)
                }
              }, 1000)

            }
          }

        }
      }

    },
    methods:{
        checkCookie(){
            const user_location = JSON.parse(document.getElementById('location-data').text)

            console.log({'this.user_location': user_location})
            let cookieStatement;
            // cookieStatement = JSON.parse(localStorage.getItem('cookieStatement'))
            cookieStatement = JSON.parse(getCookie("cookie_consent"));

            // console.log({cookieStatement})
            if (cookieStatement && (cookieStatement.consent === 'default_all' || cookieStatement.consent === 'all' || cookieStatement.consent === 'partial' || cookieStatement.consent === 'none')) {
                this.selfShow =  false;
            } else {
                const EUR_COUNTRIES = ["ZZ", "UK", "GB", "AT", "BE", "BG", "HR", "CY", "CZ", "DK", "EE", "FI", "FR", "DE", "GR", "HU", "IE", "IT", "LV", "LT", "LU", "MT", "NL", "PL", "PT", "RO", "SK", "SI", "ES", "SE"];
                // full list of european countries: https://www.yourdictionary.com/articles/europe-country-codes (ZZ is localhost)

                // simulation
                // user_location.country_code = "HU"
                // end simulation
                if (EUR_COUNTRIES.includes(user_location.country_code)) {
                    this.selfShow = true; //this.displayCookieConsentModal = true;
                } else {
                    console.log("COOKIE CONSENT MODAL: NOT DISPLAYING  BECAUSE COUNTRY = ", user_location.country_code);
                    this.selfShow = false;
                }
            }
        },
        adjustSettings(){
            this.expandedForm = true;
        },
        closeConsentModal(){
            this.$emit('close-consent-modal');
        },
        clickedInCheckbox(e){
            console.log(e.target.value);
            
            setTimeout(() => {
                const isFullAcceptance = arraysEqual(this.checkedCookieTypesArray, COOKIES_TYPE)
                // console.log(`${isFullAcceptance ? 'FULL ACCEPTED' : 'PARTIAL ACCEPTANCE'}`);
                this.acceptButtonLabel = isFullAcceptance ? 'Accept all' : 'Accept';

            }, 100);
            
            // this.clickedAtLeastOneParticularCheckbox = true
        },
      accept() {
        // function to emit (potentially) partial accepts of the user
        const isFullAcceptance = arraysEqual(this.checkedCookieTypesArray, COOKIES_TYPE)
        if (isFullAcceptance) {
          // this.$emit("cookies-accepted", {cookiesAccepted: this.checkedCookieTypesArray, consent: 'all'} );

          const COOKIES_ACCEPTED = this.checkedCookieTypesArray;
          COOKIES_ACCEPTED.push('necessary_first_party')
          console.log("FULL ACCEPTANCE, ", COOKIES_ACCEPTED)
          const payload = {cookiesAccepted: COOKIES_ACCEPTED, consent: 'all'}
          setCookie('cookie_consent', JSON.stringify(payload));
          const objectUpdate = {}
          for (const cookie_ of this.checkedCookieTypesArray) {
            objectUpdate[cookie_] = 'granted'
          }
          console.log(objectUpdate)

          gtag('consent', 'update', objectUpdate);


        } else {
          // this.$emit("cookies-accepted", {cookiesAccepted: this.checkedCookieTypesArray, consent: 'partial'} );

          const COOKIES_ACCEPTED = this.checkedCookieTypesArray;
          COOKIES_ACCEPTED.push('necessary_first_party')
          console.log("PARTIAL ACCEPTANCE, ", COOKIES_ACCEPTED)


          const payload = {cookiesAccepted: this.checkedCookieTypesArray, consent: 'partial'}
          setCookie('cookie_consent', JSON.stringify(payload));
          const objectUpdate = {}
          for (const cookie_ of this.checkedCookieTypesArray) {
            objectUpdate[cookie_] = 'granted'
          }
          console.log(objectUpdate)

          gtag('consent', 'update', objectUpdate);
        }

        this.selfShow = false;
      },

      acceptAll() {
        // this.checkedCookieTypesArray = COOKIES_TYPE
        // this.$emit("cookies-accepted",
        //             {cookiesAccepted: this.checkedCookieTypesArray, consent: 'all'} );
        const COOKIES_ACCEPTED = this.cookieTypesArray;
        COOKIES_ACCEPTED.push('necessary_first_party')
        console.log("FULL ACCEPTANCE, ", COOKIES_ACCEPTED)
        const payload = {cookiesAccepted: COOKIES_ACCEPTED, consent: 'all'}
        setCookie('cookie_consent', JSON.stringify(payload));
        const objectUpdate = {}
        for (const cookie_ of this.cookieTypesArray) {
          objectUpdate[cookie_] = 'granted'
        }
        console.log(objectUpdate)

        gtag('consent', 'update', objectUpdate);
        this.selfShow = false;

      },
      declineAll() {
        // this.$emit("decline-all-cookies", {cookiesAccepted: [], consent: 'none'});
        this.checkedCookieTypesArray = []
        console.log("DECLINE ALL, ", this.checkedCookieTypesArray)
        const payload = {cookiesAccepted: ['necessary_first_party'], consent: 'none'}
        setCookie('cookie_consent', JSON.stringify(payload));

        this.selfShow = false;

      }
    }
}

</script>

<style lang="scss" scoped>
.consent-cookie-container {
    position: fixed;
    z-index: 2147483001; /* one more than help frame */
    bottom: 25px;
    width: 100vw;
    height: auto;
    background-color: #fff;
    padding: 10px;
    color: #1E1C2A;
    border-radius: 10px 10px 0 0;

    &__title{
        margin: 10px 0;
    }

    &__close-button{
       position: absolute;
       right: 15px;
       top: 10px;
       cursor: pointer;
    }

    &__description {
        margin: 10px 0;

        & .adjust-settings{
            text-decoration: underline;
            cursor: pointer;
        }
    }

    & .input-container {
        padding: 3px;
        & input[type="checkbox"] {
            display: inline-block;
            margin-bottom: -5px;
            margin-right: 5px;
            // this is needed to override input[type="checkbox"] generic styles in _forms.scss
            appearance: auto;
    
        }

        & label {
            width: unset;
            position: relative;
            bottom: 5px;
        
        }
    }

    & .accept-decline-container{
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;

        & button{
            // flex: 1 1 50%;
            padding: 5px 10px;
            margin:  5px 10px;
            border-radius: 10px;
            border: none;
            width: 43%;
            background-color: #E8E9F3;
        }
        & .accept{
            background-color: #1E1C2A;
            color: #FFFFFF;
        }

       
    }


}


@media (min-width: 767px) {
    .consent-cookie-container {
        width: 400px;
        right: 10px;
        bottom: 10px;
        border-radius: 10px;
   
    }


}

</style>