<template>
  <observer @on-change="(intersectionObserverEntry) => toggleVisibility(intersectionObserverEntry)">
    <div>
      <div class="w-100 py-2 px-3 mb-3">
        <div class="align-items-center justify-content-between d-flex">
          <span class="d-block font-size-16 text-secondary font-bold">Rooms</span>
          <div class="d-flex">
            <a class="btn-icon btn-medium font-15  btn-outline-secondary rounded-circle"
              :disabled="accommodation.rooms.min === selectedAccommodation.length" @click="removeRoom">
              <!-- disabled="disabled" -->
              <small class="fa fa-minus btn-icon__inner"></small>
            </a>
            <input type="text" class="form-control max-width-3 text-center search-qty" readonly="readonly"
              :min="accommodation.rooms.min" :max="accommodation.rooms.max" v-bind:value="selectedAccommodation.length">
            <a class="btn-icon btn-medium font-15  btn-outline-secondary rounded-circle"
              :disabled="accommodation.rooms.max === selectedAccommodation.length" @click="addRoom">
              <!-- disabled="disabled"  -->
              <small class="fa fa-plus btn-icon__inner"></small>
            </a>
          </div>
        </div>
      </div>
      <div class="border-top border-width-1 border-green" v-for="(selectedRoom, roomIndex) of selectedAccommodation"
        :key="roomIndex">
        <div class="w-100 py-2 px-3 mb-3 pt-6">
          <div class="align-items-center justify-content-between d-flex">
            <span class="d-block font-size-16 text-secondary">Adults</span>
            <div class="d-flex">
              <a class="btn-icon btn-medium font-15  btn-outline-secondary rounded-circle"
                :disabled="selectedRoom.adults === accommodation.adults.min" @click="removeGuest(selectedRoom, 'adult')">
                <!-- disabled="disabled"  -->
                <small class="fa fa-minus btn-icon__inner"></small>
              </a>
              <input type="text" class="form-control max-width-3 text-center search-qty" readonly="readonly"
                :min="accommodation.adults.min" :max="accommodation.adults.max" v-model="selectedRoom.adults"
                :key="roomIndex">
              <a class="btn-icon btn-medium font-15  btn-outline-secondary rounded-circle"
                :disabled="selectedRoom.adults === accommodation.adults.max" @click="addGuest(selectedRoom, 'adult')">
                <small class="fa fa-plus btn-icon__inner"></small>
              </a>
            </div>
          </div>
        </div>

        <div class="w-100 py-2 px-3 mb-3">
          <div class="align-items-center justify-content-between d-flex">
            <span class="d-block font-size-16 text-secondary">Child</span>
            <div class="d-flex">
              <a class="btn-icon btn-medium font-15  btn-outline-secondary rounded-circle"
                :disabled="selectedRoom.children.length === accommodation.children.min"
                @click="removeGuest(selectedRoom, 'child')">
                <!-- disabled="disabled"  -->
                <small class="fa fa-minus btn-icon__inner"></small>
              </a>
              <input type="text" class="form-control max-width-3 text-center search-qty" readonly="readonly"
                :min="accommodation.children.min" :max="accommodation.children.max"
                v-bind:value="selectedRoom.children.length">
              <a class="btn-icon btn-medium font-15  btn-outline-secondary rounded-circle"
                :disabled="selectedRoom.children.length === accommodation.childrenMax"
                @click="addGuest(selectedRoom, 'child')">
                <!-- disabled="disabled"  -->
                <small class="fa fa-plus btn-icon__inner"></small>
              </a>
            </div>
          </div>
        </div>
        <template v-if="selectedRoom.children.length">
          <div class="w-100 py-2 px-3 mb-3">
            <div class="row">
              <div class="col-md-6 col-sm-6" v-for="(childAge, ageIndex) in selectedRoom.children" :key="ageIndex">
                <p class="d-block font-13 text-secondary">Children {{ ageIndex + 1 }} Age</p>
                <select class="form-control w-75 text-center font-black" v-model="selectedRoom.children[ageIndex]">
                  <option v-for="(n, index) in accommodation.children.maxAge + 1" :key="index">{{ n - 1 }}</option>
                </select>
              </div>
            </div>
          </div>
        </template>
      </div>
      <div class="form-group" style="text-align: center;">
        <button type="button" class="btn btn-primary btn-small mt-2" style="width: 80%;"
          @click.stop="doneClicked"
        >
          Done
        </button>
      </div>
    </div>
  </observer>
</template>
<script>

import VueI18n from 'vue-i18n';
import store from '../stores/store'
import messages from '../common/messages';
import observer from "./observer.vue";


export default {
  
  store: store,
  i18n: new VueI18n({
    locale: 'en',
    messages,
  }),
  props: {
    paxes: {
      type: Array,
      required: true
    },
  },
  components: {
    observer
  },
  data() {
    return {
      GUEST_TYPES: {
        adult: 'adult',
        child: 'child',
      },
      //isDesktop: true,
      isVisible: false,
      // isChanged: false,
      accommodation: {
        rooms: {
          min: 1,
          max: 10,
        },
        adults: {
          min: 1,
          max: 5,
        },
        children: {
          min: 0,
          max: 10,
          defaultAge: 0,
          maxAge: 12,
        }
      },
      originalPaxes: undefined,
      selectedAccommodation: [{ adults: 2, children: [] }],
    }
  },
  mounted(){
    if(this.paxes.length > 0){
      // Hack to deep copy the pax data without any of the vuex reactivity tagging along
      this.selectedAccommodation = JSON.parse(JSON.stringify(this.paxes));
      this.originalPaxes = JSON.stringify(this.paxes);
    }
  },
          
  computed: {
    paxesChanged(){
      return this.originalPaxes != JSON.stringify(this.selectedAccommodation)
    }
  },
  methods: {
    toggleVisibility(intersectionObserverEntry){
      //console.log([intersectionObserverEntry.intersectionRatio, this.isVisible])
      if(intersectionObserverEntry.intersectionRatio == 0 && this.paxesChanged){
        this.doneClicked();
        //if(this.isChanged){
          //this.$emit('accommodation-changed', this.selectedAccommodation)
        //}
        //this.$emit('done-clicked');
        //this.isVisible = false;
      }
    },
    doneClicked(){
      let paxesChanged = this.paxesChanged;
      if(this.paxesChanged){
        this.originalPaxes = JSON.stringify(this.selectedAccommodation)
      }
      this.$emit('finished', this.selectedAccommodation, paxesChanged);
    },
    addRoom() {
      if (this.selectedAccommodation.length < this.accommodation.rooms.max) {
        this.$set(this.selectedAccommodation, this.selectedAccommodation.length, { adults: 2, children: [] })
      }
      this.$emit('accommodation-changed', this.selectedAccommodation);
    },
    removeRoom() {
      if (this.selectedAccommodation.length > this.accommodation.rooms.min) {
        this.selectedAccommodation.pop();
        //this.selectedAccommodation.splice(newLength)
      }
      this.$emit('accommodation-changed', this.selectedAccommodation)
    },
    addGuest(room, guestType) {
      if (guestType == this.GUEST_TYPES.adult && room.adults < this.accommodation.adults.max) {
        room.adults++;
      } else if (guestType == this.GUEST_TYPES.child && room.children.length < this.accommodation.children.max) {
        room.children.push(this.accommodation.children.defaultAge);
      }
      this.$emit('accommodation-changed', this.selectedAccommodation)
    },
    removeGuest(room, guestType) {
      if (guestType == this.GUEST_TYPES.adult && room.adults > this.accommodation.adults.min) {
        room.adults--;
      } else if (guestType == this.GUEST_TYPES.child && room.children.length > this.accommodation.children.min) {
        room.children.pop();
      }
      this.$emit('accommodation-changed', this.selectedAccommodation)
    },
  },
  /*watch: {
    selectedAccommodation:
    {
      handler: function (n, o) {
        this.isChanged = true;
      },
      deep: true
    }
  }*/
}
</script>
