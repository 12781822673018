<template>
  <div class="search-dropdown">
    <div
      @click="onInputClick"
      class="search-dropdown__input-container"
      :class="{'search-dropdown__input-container_open': state.editing && results.length}"
      v-click-outside="blur"
    >
      <svg
        v-if="state.loading"
        class="icon icon-loading"
      >
        <use xlink:href="/static/autogen/img/sprite.svg#loading"></use>
      </svg>
      <template v-else-if="selectedResult">
        <svg
          v-if="selectedResult.item == 'hotel'"
          class="icon icon-accommodation"
        >
          <use xlink:href="/static/autogen/img/sprite.svg#accommodation"></use>
        </svg>
        <svg
          v-else
          class="icon icon-location"
        >
          <use xlink:href="/static/autogen/img/sprite.svg#location"></use>
        </svg>
      </template>
      <svg
        v-else
        class="icon icon-search"
      >
        <use xlink:href="/static/autogen/img/sprite.svg#search"></use>
      </svg>
      <input
        v-model.trim="models.query"
        :placeholder="placeholder"
        @input="onInput"
        class="search-dropdown__input"
        type="text"
        ref="input"
      >
      <svg
        v-if="state.editing && models.query"
        class="icon icon-close"
        @click.stop="clearInput"
      >
        <use xlink:href="/static/autogen/img/sprite.svg#close"></use>
      </svg>
    </div>
    <transition name="slide-down" appear>
      <ul
        v-if="state.editing && results.length"
        class="search-dropdown__results"
      >
        <li
          v-for="result in convertedResults"
          @mousedown.prevent="onSearchResultSelect(result)"
          class="search-dropdown-item"
        >
          <div class="search-dropdown__icon">
            <svg
              v-if="result.item == 'hotel'"
              class="icon icon-accommodation"
            >
              <use xlink:href="/static/autogen/img/sprite.svg#accommodation"></use>
            </svg>
            <svg
              v-else
              class="icon icon-location"
            >
              <use xlink:href="/static/autogen/img/sprite.svg#location"></use>
            </svg>
          </div>
          <div class="search-dropdown-item__content">
            <div class="search-dropdown-item__header">
              <div class="search-dropdown-item__name">
                {{ result.name }}
              </div>
              <div
                v-if="result.type"
                class="search-dropdown-item__type"
              >
                {{ result.type }}
              </div>
            </div>
            <div class="search-dropdown-item__description">
              {{ result.description }}
            </div>
          </div>
        </li>
      </ul>
    </transition>
  </div>
</template>
<script>

import debounce from 'lodash/debounce';
import VueGeolocation from 'vue-browser-geolocation';

import Destination from '../api/destination';
import clickOutside from '../directives/click-outside';

import { readDataFromContext } from '../../utils/utils.js';



const SEARCH_DELAY = 500;
const GOOGLE_SEARCH_DELAY = 5000;

Vue.use(VueGeolocation);

export default {
  components: {
  },
  directives: {
    'click-outside': clickOutside
  },
  props: {
    initial: {
      type: Object,
      required: false,
    },
    placeholder: {
      type: String,
      default: 'Where are you going?',
    },
    // resultsVisible: {
    //   type: Boolean,
    //   default: false,
    // },
    // autofocus: {
    //   type: Boolean,
    //   default: false,
    // },
    // customClass: {
    //   type: Array,
    //   default() { return [] },
    // },
    // regionId: {
    //   type: String,
    //   default: '',
    // },
    regionsSize: {
      type: Number,
      default: 5,
    },
    hotelsSize: {
      type: Number,
      default: 5,
    },
    // grabFocus: {
    //   type: Boolean,
    //   default: false
    // },
    // windowWidth: {
    //   type: Number,
    //   required: false
    // }
  },
  data() {
    return {
      models: {
        query: '',
      },
      results: [],
      selectedResult: null,
      
      state: {
        performInitialSearch: false,
        editing: false,
        loading: false,
      //   geolocationFailed: false
      }
    }
  },
  computed: {
    convertedResults() {
      return this.results.map(result => {
        if(result.item == 'region') {
          return {
            ...result,
            type: result.type.replaceAll('_', ' '),
            description: result.name_full,
          }
        } else if(result.item == 'hotel') {
          return {
            ...result,
            description: result.full_address,
          }
        }
      })
    }
  },
  methods: {
    focus() {
      this.models.query = '';
      this.$refs['input'].focus();
    },
    search(query) {
      let searchParams = {
        query: query,
        regions_size: this.regionsSize,
        hotels_size: this.hotelsSize,
      }
      this.state.loading = true;
      Destination.search(searchParams)
        .then((response) => {
          this.results = response.data;
        }).finally(() => {
          this.state.editing = true;
          this.state.loading = false;
          this.state.performInitialSearch = false;
        })
    },
    setSelectedResult(result) {
      this.selectedResult = result;
      this.models.query = result.name;
      if(result.item == 'hotel') {
        this.models.query = result.name;
      } else {
        this.models.query = result.name_full;
      }
    },
    onInputClick() {
      this.state.editing = true;
      if(this.state.performInitialSearch && this.models.query) {
        this.search(this.models.query);
      }
      this.$refs['input'].focus();
    },
    onInput: debounce(function(event){
      let query = event.target.value;
      if(!query.length) {
        return;
      }
      // this.state.loading = true;
      this.search(this.models.query);
    }, SEARCH_DELAY),
    clearInput() {
      this.models.query = '';
      this.$refs['input'].focus();
    },
    blur(e) {
      this.state.editing = false;
    },
    // onBlur(e) {
    //   console.log('blur', e.target);
    //   this.state.editing = false;
    // },
    onSearchResultSelect(result) {
      this.setSelectedResult(result);
      this.$emit('result-selected', result);
      // submitLogEvent('success_new_destination', {destination_id: result.id});
      this.blur();
      this.$refs['input'].blur();
    }
  },
  beforeMount(){
    if(this.initial) {
      this.setSelectedResult(this.initial);
      this.state.performInitialSearch = true;
    }
    // let initialRegion = readDataFromContext('#initial-region');
    // if(initialRegion) {
    //   this.setSelectedResult(initialRegion);
    //   this.state.performInitialSearch = true;
    // }
  },
}
</script>
<style scoped lang="scss">
@use 'activeSite' as site;
@use 'sassRoot/breakpoints';

.search-dropdown {
  position: relative;


  &__input-container {
    position: relative;
    display: flex;
    align-items: center;
    padding: 0 15px;
    font-family: 'Manrope';
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    background-color: site.$colorLut_white;
    border-radius: 10px;
    overflow: hidden;
    cursor: pointer;
    gap: 10px;

    &_open {
      @include breakpoints.breakpoint('laptop') {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
    }

    & .icon {
      width: 24px;
      height: 24px;
    }

    & .icon-loading {
      -webkit-animation: fa-spin 2s infinite linear;
      animation: fa-spin 2s infinite linear;
    }

    & .icon-close {
      position: absolute;
      right: 15px;
      top: 0;
      bottom: 0;
      margin-top: auto;
      margin-bottom: auto;
    }
  }

  &__input {
    border: none !important;
    outline: none !important;
    box-shadow: none;
    padding: 18px 45px 18px 0;
    color: black;
    font-family: 'Manrope';
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    
    &:hover,
    &:focus {
      box-shadow: none !important;
    }
  }

  &__results {
    width: 100%;
    padding: 0;
    margin-top: 10px;
    list-style-type: none;
    background-color: site.$colorLut_white;
    border-radius: 10px;

    @include breakpoints.breakpoint('laptop') {
      position: absolute;
      left: 0;
      z-index: 3000;
      background-color: site.$colorLut_white;
      border-radius: 0;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
      margin: 0;
      list-style-type: none;
      overflow: hidden;
    }

    & li {
      border-top: 1px solid #DDDBDD;
    }
  }
}
.search-dropdown-item {
  display: flex;
  align-items: center;
  padding: 6px 15px;
  cursor: pointer;
  gap: 10px;
  &:hover {
    color: site.$colorLut_white;
    background-color: site.$colorLut_blue;
    & .icon {
      fill: site.$colorLut_white;
    }
  }
  &:hover &__name {
    color: site.$colorLut_white;
  }
  & .icon {
    width: 24px;
    height: 24px;
  }
  &__content {
    flex: 1 1 auto;
  }
  &__header {
    display: flex;
  }
  &__name {
    flex: 1 1 auto;
    color: black;
  }
  &__type {
    text-transform: capitalize;
  }
  &__description {
    margin-top: 2px;
    font-size: 12px;
    line-height: 18px;
  }
}

@-webkit-keyframes loading-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@keyframes loading-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
</style>